import React from "react";

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="max-w-2xl flex-grow">
    <div className="flex p-2 w-full justify-center relative pb-9/16">
      <iframe
      className='w-full h-full absolute top-0 left-O'
      src={videoSrcURL}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title={videoTitle}>
      </iframe>
    </div>
  </div>
)
export default Video