import { graphql } from "gatsby";
import React from 'react';
import {
  Accordion,
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Layout from '../components/layout/Layout';
import Video from "../components/Video";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

const Faq = ({
  location,
  data: {
    allFile : { edges }
  }
}) => {
  const {t} = useI18next()
  return (
    <Layout location={location}>
      <section className="pt-8">
        <div className="text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold pb-8"><Trans>faq</Trans></h2>
        </div>
        <div className="container mx-auto px-8">
          <Accordion allowZeroExpanded>
            { 
              edges?.map((object) => {

                if(object.node.childMarkdownRemark.frontmatter.videoSourceURL)
                {
                  return (
                    <AccordionItem key={object.node.id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {t(object.node.childMarkdownRemark.frontmatter.title)}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="md:p-12 p-4">
                        <div className="flex m-0.5 justify-center">
                          <Video videoSrcURL={object.node.childMarkdownRemark.frontmatter.videoSourceURL} videoTitle={object.node.childMarkdownRemark.frontmatter.videoTitle} />
                        </div>
                        {
                          object.node.childMarkdownRemark.html && 
                          <div className="md:mt-12 mt-4" dangerouslySetInnerHTML={{ __html: object.node.childMarkdownRemark.html }}>
                          </div>
                        }
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                } else{
                  return (
                    <AccordionItem key={object.node.childMarkdownRemark.id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {object.node.frontmatter.childMarkdownRemark.title}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="md:p-12 p-4" dangerouslySetInnerHTML={{ __html: object.node.childMarkdownRemark.html }}>
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                }
              })
            }
          </Accordion>
        </div>
      </section>
    </Layout>
)};

export default Faq;

export const pageQuery = graphql`
  query ($language: String!){
    allFile(
      filter: {sourceInstanceName: {eq: "faq"}, childMarkdownRemark: {fields: {langKey: {eq: $language}}, frontmatter: {published: {eq: true}}}}
      sort: {fields: childrenMarkdownRemark___frontmatter___tag}
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              videoSourceURL
              videoTitle
            }
            html
          }
        }
      }
    }
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
